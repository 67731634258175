import { Grid } from "@material-ui/core";
import { Link } from "gatsby";
import React, { useEffect } from "react";
import banner1 from "../../assets/Images/WhatWeDo/how_we_do_it_2x.png";
import banner2 from "../../assets/Images/WhatWeDo/We_d_love_to_help_with_your_product._2x.png";
import banner3 from "../../assets/Images/WhatWeDo/What_we_do_2x.png";
import banner4 from "../../assets/Images/WhatWeDo/who_we_work_with_2x.png";
import banner5 from "./hire_remote_team_mobile@2x.png";
import Button from "../../components/Button";
import "./MiniImageBanner1.scss";

const MiniImageBanner1 = (props) => {
  useEffect(() => {
    document.getElementById(
      `${props.id}`
    ).style.backgroundImage = `url(${props.MiniImageBanner1Data.image})`;
  }, [props.MiniImageBanner1Data.image, props.id]);
  const bannerImg =
    props.id === "one" && props.MiniImageBanner1Data.route === "/how-we-do-it"
      ? banner1
      : props.MiniImageBanner1Data.route === "/contact-us"
      ? banner2
      : props.MiniImageBanner1Data.route === "/what-we-do"
      ? banner3
      :props.MiniImageBanner1Data.route === "/hire-remote-teams"
      ?banner5
      : banner4;
  return (
    <React.Fragment>
      <Link
        target="_blank"
        className="banner-condition services-link"
        to={props.MiniImageBanner1Data.route}
      >
        <div className="mini-image-banner1" id={props.id}>
          <div className="body-container">
            {/* Hiding this temporarily */}
            <div className="mini-image-banner-overlay1">
              <Grid container direction="row" spacing={3} alignItems="center">
                <Grid item md={10}>
                  <div>
                    {props.MiniImageBanner1Data.tag === "h2" ? (
                      <h2 className={props.MiniImageBanner1Data.titleClass}>
                        {props.MiniImageBanner1Data.title}
                      </h2>
                    ) : (
                      <h3 className={props.MiniImageBanner1Data.titleClass}>
                        {props.MiniImageBanner1Data.title}
                      </h3>
                    )}
                    {props.MiniImageBanner1Data.underline && (
                      <div
                        className={props.MiniImageBanner1Data.underlineClass}
                      ></div>
                    )}
                  </div>
                </Grid>
                {props.MiniImageBanner1Data.btnLabel && (
                  <Grid item md={2}>
                    <Button
                      customClassName={props.MiniImageBanner1Data.btnClassName}
                      variant={props.MiniImageBanner1Data.btnVariant}
                      color={props.MiniImageBanner1Data.btnColor}
                      label={props.MiniImageBanner1Data.btnLabel}
                      tip={props.MiniImageBanner1Data.tip}
                      tooltip={props.MiniImageBanner1Data.tooltip}
                      routepath={props.MiniImageBanner1Data.route}
                    />
                  </Grid>
                )}
              </Grid>
            </div>
          </div>
        </div>
      </Link>
      <Link
        target="_blank"
        className="services-link"
        to={props.MiniImageBanner1Data.route}
      >
        <img className="mbl-banner" src={bannerImg} alt={bannerImg} />
      </Link>
    </React.Fragment>
  );
};

export default MiniImageBanner1;
